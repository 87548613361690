<template>
  <div class="d-flex flex-column">
    <div >
      <v-menu offset-y :rounded="false" :close-on-content-click="false" transition="slide-y-transition" bottom left v-model="menu" style="box-shadow: none !important">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" dense small outlined block color="primary ">Upload Clinical Data</v-btn>
        </template>
        <div class="d-flex flex-row pa-6 grey lighten-4" style="width: 400px">
          <v-file-input
            :disabled="state == State.UPLOADING"
            class="caption"
            hide-details
            show-size
            truncate-length="40"
            accept="application/x-gzip, application/zip"
            :placeholder="`Select the archive file `"
            :rules="fileValidationRules"
            v-model="file"
            outlined
            dense
          ></v-file-input>
          <v-btn @click="onUpload" icon :disabled="!file || state == State.UPLOADING" color="primary"><v-icon>mdi-upload</v-icon></v-btn>
        </div>
      </v-menu>
    </div>
    <v-progress-linear v-if="state == State.UPLOADING" class="mt-1" color="teal" buffer-value="0" :value="percentage" stream></v-progress-linear>
    <div style="width: 210px">
      <div v-if="state == State.UPLOADING" class="orange--text caption"><v-icon small color="orange">mdi-alert-outline</v-icon> Do not leave or refresh the page</div>
      <div v-if="state == State.UPLOAD_FAILED" class="red--text caption"><v-icon small color="red">mdi-alert-outline</v-icon> Upload failed! {{ err }}</div>
      <div v-if="state == State.UPLOAD_SUCCESS" class="green--text caption"><v-icon small color="green">mdi-check-circle-outline</v-icon> Upload succeeded</div>
    </div>
  </div>
</template>

<script>
const State = {
  NOOP: 0,
  UPLOADING: 1,
  UPLOAD_SUCCESS: 2,
  UPLOAD_FAILED: 3,
};

import { uploadClinicalData, getClinicalDataUploadSignedUrls } from "@/api/user/seqdata.js";
export default {
  name: "SeqFileUploader",
  props: {
    cohortId: {
      type: Number,
      required: true,
    },
    projectId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    file: null,
    menu: false,
    fileValidationRules: [(value) => !value || value.size < 500 * 1024 * 1024 || "Clinical data file size should be less than 500 MB!"],
    uploadId: null,
    state: State.NOOP,
    err: null,
    State: State,
    percentage: 0,
  }),
  methods: {
    onUpload() {
      this.menu = false;
      this.state = State.UPLOADING;
      const fileName = this.file.name;
      const _this = this;
      this.percentage = 0;
      getClinicalDataUploadSignedUrls(this.cohortId, this.projectId, fileName, (res) => {
        console.log(res.url);
        uploadClinicalData(
          res.url,
          _this.file,
          (res) => {
            _this.state = State.UPLOAD_SUCCESS;
            console.log("DONE:", res);
            this.$emit("onUploaded");
          },
          (err) => {
            _this.state = State.UPLOAD_FAILED;
            console.log("FAILED:", err);
          },
          (percent) => {
            _this.percentage = percent
            // console.log("PROGRESS:", percent);
          }
        );
      });
      //   console.log(this.id, fastqNo);
    },
  },
};
</script>