<template>
  <div class="d-flex flex-column">
    <div class="d-flex flex-row ml-auto" style="height: 40px">
      <div>
        <v-progress-circular class="mt-2" v-if="state == State.UPLOADING" :rotate="360" :size="30" :width="4" :value="percentage" color="teal">
          {{ percentage }}
        </v-progress-circular>
      </div>
      <div class="my-auto">
        <v-menu offset-y :rounded="false" :close-on-content-click="false" transition="slide-y-transition" bottom left v-model="menu" style="box-shadow: none !important">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon large class="my-auto ml-2 " color="primary lighten-1" > <v-icon>mdi-upload</v-icon> </v-btn>
            <!-- <v-btn v-bind="attrs" v-on="on" icon class="my-auto ml-2 mt-1" color="primary lighten-1" > <v-icon>mdi-upload</v-icon> </v-btn> -->
          </template>
          <div class="d-flex flex-row pa-6 grey lighten-4" style="width: 500px">
            <v-file-input
              :disabled="state == State.UPLOADING"
              class="caption"
              hide-details
              show-size
              truncate-length="40"
              :accept="accept"
              :placeholder="placeholder"
              :rules="fileValidationRules"
              v-model="file"
              outlined
              dense
            ></v-file-input>
            <v-btn class="mt-1 ml-1" @click="onUpload" outlined small :disabled="!file || state == State.UPLOADING" color="primary">Upload</v-btn>
          </div>
        </v-menu>
      </div>
    </div>
    <div style="width: 210px" class="ml-auto">
      <div v-if="state == State.UPLOADING " class="orange--text caption"><v-icon small color="orange">mdi-alert-outline</v-icon> Do not leave or refresh the page</div>
      <div v-if="state == State.UPLOAD_FAILED " class="red--text caption"><v-icon small color="red">mdi-alert-outline</v-icon> Upload failed! {{ err }}</div>
      <div v-if="state == State.UPLOAD_SUCCESS " class="green--text caption"><v-icon small color="green">mdi-check-circle-outline</v-icon> Upload succeeded</div>
    </div>
  </div>
</template>

<script>
const State = {
	NOOP: 0,
	UPLOADING: 1,
	UPLOAD_SUCCESS: 2,
	UPLOAD_FAILED: 3
}
import Uploader from "@/utils/s3Uploader.js";

import { getCohortSeqDataUploadParts, CompleteCohortSeqDataSampleUpload } from "@/api/user/seqdata.js";
export default {
  name: "SeqFileUploader",
  props: {
    fileType:{
      type: String,
      required: true,
    },
    sampleName: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    seqFileNo: {
      type: Number,
      required: true,
    },
    cohortId: {
      type: Number,
      required: true,
    },
    projectId: {
      type: Number,
      required: true,
    },
    
  },
  data: () => ({
    file: null,
    menu: false,
    // fileValidationRules: [(value) => !value || value.size < 50*1024*1024*1024 || "Fastq file size should be less than 50 GB!"],
    // CHUNK_SIZE: 500 * 1024 * 1024, //500MB,
    CHUNK_SIZE: 200 * 1024 * 1024, //200MB,
    THREAD_COUNT: 10,
    uploadId: null,
    percentage: 0,
    state: State.NOOP,
    err: null,
    State:State
  }),
  computed:{
    fileValidationRules(){
      const limit = (this.fileType == 'FASTQ'? 50 : 70)
      return [(value) => !value || value.size < limit*1024*1024*1024 || `Fastq file size should be less than ${limit} GB!`]
    },
    accept(){
      if(this.fileType == 'FASTQ'){
        return "application/x-gzip"
      } else {
        return ".bam, .BAM"
      }
    },
    placeholder(){
      if(this.fileType == 'FASTQ'){
        return `Click here to select FASTQ R${this.seqFileNo} file for ${this.sampleName} (*.qz)`
      } else {
        return `Click here to select BAM file for ${this.sampleName}  (*.bam)`
      }
    }
  },
  methods: {
    sendCompleteRequest(uploadedParts) {
      const _this = this;
      uploadedParts.sort((a,b)=> a.PartNumber - b.PartNumber )
      console.log(uploadedParts)
      CompleteCohortSeqDataSampleUpload(this.cohortId, this.projectId, this.id, this.seqFileNo, this.uploadId, uploadedParts, () => {
        _this.state = State.UPLOAD_SUCCESS;
        _this.$emit("onUploaded",this.id, this.seqFileNo)
      }, ()=>{
        _this.state = State.UPLOAD_FAILED;

      });
    },
    onUpload() {
      this.menu = false;
      this.state = State.UPLOADING;

      //   this.isUploading = true;
      const fileName = this.file.name;
      const numberOfparts = Math.ceil(this.file.size / this.CHUNK_SIZE);
      const _this = this;
      getCohortSeqDataUploadParts(this.cohortId, this.projectId, this.id, this.seqFileNo, fileName, numberOfparts, (res) => {
        const uploaderOptions = {
          useTransferAcceleration: false,
          chunkSize: _this.CHUNK_SIZE,
          threadsQuantity: _this.THREAD_COUNT,
          file: _this.file,
          fileName: fileName,
          parts: res.parts.map((part) => {
            return { signedUrl: part.url, PartNumber: part.part_number };
          }),
          sendCompleteRequestCallback: _this.sendCompleteRequest,
        };
        _this.uploadId = res.upload_id;

        const uploader = new Uploader(uploaderOptions);
        uploader
          .onProgress(({ percentage: newPercentage }) => {
            if (newPercentage !== this.percentage) {
              this.percentage = newPercentage;
            }
          })
          .onError((error) => {
            // setFile(undefined);
            this.state = State.UPLOAD_FAILED;
            this.err = error;
            // this.isUploading = false;
            console.error(error);
          });
        uploader.start();

        console.log(res);
      });

      //   console.log(this.id, fastqNo);
    },
  },
};
</script>
<style scoped>
::v-deep .v-progress-circular__info {
  font-size: 11px !important;
}
</style>