<template>
  <div class="sampleRow body-2 d-flex flex-row">
    <div class="d-flex align-center ml-4" style="width: 180px !important"><span class="grey--text text--darken-3 font-weight-medium subtitle-1">{{ sample.sample_name }}</span> </div>
    <div class="mt-1">
      <v-radio-group hide-details dense class="mt-0" v-model="uploadType">
        <v-radio label="FASTQ file" value="FASTQ"></v-radio>
        <v-radio label="BAM file" value="BAM"></v-radio>
      </v-radio-group>
    </div>
    <div class="d-flex flex-row flex-grow-1 ml-10 mr-4 my-auto">
      <div class="d-flex flex-row flex-grow-1 mr-4">
        <div v-if="sample.fastq1_status === 'uploaded'">
          <div class="text-left">
            {{ sample.fastq1 }}
          </div>
          <div class="d-flex flex-row caption grey--text">
            <div>LastUpdate :{{ toDateTimeString(sample.fastq1_uploaded_at) }}</div>
          </div>
        </div>
        <div v-if="sample.fastq1_status === 'new'" class="caption grey--text mt-2">Click on <v-icon small color="#999999">mdi-upload</v-icon> to select and upload  {{ uploadType=='BAM'?'BAM': 'FASTQ R1'}} file for {{ sample.sample_name }}</div>
        <SeqFileUploader class="ml-auto" :fileType="uploadType" :sampleName="sample.sample_name" :id="sample.id" :seqFileNo="1" :cohortId="cohortId" :projectId="projectId" @onUploaded="onFileUploaded"></SeqFileUploader>
      </div>
      <div class="d-flex flex-row flex-grow-1" v-if="uploadType == 'FASTQ'">
        <div v-if="sample.fastq2_status === 'uploaded'">
          <div class="text-left">
            {{ sample.fastq2 }}
          </div>
          <div class="d-flex flex-row caption grey--text">
            <div>LastUpdate :{{ toDateTimeString(sample.fastq2_uploaded_at) }}</div>
          </div>
        </div>
        <div v-if="sample.fastq2_status === 'new'" class="caption grey--text mt-2">Click on <v-icon small color="#999999">mdi-upload</v-icon> to select and upload   FASTQ R2 file for {{ sample.sample_name }}</div>
        <SeqFileUploader class="ml-auto" :fileType="uploadType" :sampleName="sample.sample_name" :id="sample.id" :seqFileNo="2" :cohortId="cohortId" :projectId="projectId" @onUploaded="onFileUploaded"></SeqFileUploader>
      </div>
    </div>
  </div>
</template>
<script>
import { toDateTimeString } from "@/utils/datetime.js";
import SeqFileUploader from "@/components/UploadSequenceData/SeqFileUploader.vue";
export default {
  name: "SampleRowUploader",
  components: { SeqFileUploader },
  props: {
    sample: {
      type: Object,
      required: true,
    },
    cohortId: {
      type: Number,
      default: null,
    },
    projectId: {
      type: Number,
      default: null,
    },
  },
  data:()=>( {
    uploadType: null,
  }),
  created() {
    if (!this.sample.fastq1 || this.sample.fastq1.toLowerCase().endsWith("gz")) {
      this.uploadType = "FASTQ";
    } else {
      this.uploadType = "BAM";
    }
  },
  methods:{
    toDateTimeString: toDateTimeString,
    onFileUploaded(id) {
      this.$emit("onFileUploaded", id);
    },

  }
};
</script>