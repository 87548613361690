import ApiIntegrface from "../api"
export function getCohortSeqData(cohortId, projectId, callback){
  const api = new ApiIntegrface()
  const url = `/api/v1/cohorts/${cohortId}/seqData`
  api.get(url, null, callback)
}

export function addCohortSeqDataSample(cohortId, projectId, sampleName, callback, errHandler){
  const api = new ApiIntegrface()
  const url = `/api/v1/cohorts/${cohortId}/seqData`
  const payload =  {
    "sample_name": sampleName
}
  api.post(url, payload, callback, errHandler)
    // callback({seqDataId:3, sampleName:sampleName, fastq1Status: 'NEW', fastq2Status: 'NEW' })
}

export function getCohortSeqDataUploadParts(cohortId, projectId, seqFileId, seqFileNo , fileName,numberOfParts,  callback){
   const api = new ApiIntegrface()
   const url = `/api/v1/cohorts/${cohortId}/seqData/${seqFileId}/${seqFileNo}`
   const payload =  {
    "numberOfParts": numberOfParts,
    "fileName": fileName
    }
   api.post(url, payload, callback)
}


export function CompleteCohortSeqDataSampleUpload(cohortId, projectId,  seqFileId, seqFileNo , uploadId, parts, callback, errHandler){
    const api = new ApiIntegrface()
    const url = `/api/v1/cohorts/${cohortId}/seqData/${seqFileId}/${seqFileNo}/complete`
    const payload =  {
      "upload_id": uploadId,
      "parts_info":parts
    }
    api.post(url, payload, callback,errHandler)
      // callback({seqDataId:3, sampleName:sampleName, fastq1Status: 'NEW', fastq2Status: 'NEW' })
  }

export function getClinicalData(cohortId, projectId, callback){
    const api = new ApiIntegrface()
    const url = `/api/v1/cohorts/${cohortId}/clinical-data`
    api.get(url, null, callback)
}
  
export function getClinicalDataUploadSignedUrls(cohortId, projectId, fileName, callback){
  const api = new ApiIntegrface()
  const url = `/api/v1/cohorts/${cohortId}/clinical-data/upload`
  const payload = {
    fileName:fileName
  }
  api.post(url, payload, callback)
}


export  function uploadClinicalData(presignedUrl, file,callback, errHandler, progressHandler) {
  // const myHeaders = new Headers({ 'Content-Type': 'application/*' });
  // const response = await fetch(presignedUrl, {
  //     method: 'PUT',
  //     headers: myHeaders,
  //     body: file
  //  });

    var xhr = new XMLHttpRequest();
    xhr.open('PUT', presignedUrl, true);
    xhr.setRequestHeader('Content-Type', 'application/*');
    xhr.onload = () => {
      if (xhr.status === 200) {
        callback()
      }
    };
    xhr.onerror = () => {
      errHandler()
    };
    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        var percent = Math.round((event.loaded / event.total) * 100)
        progressHandler(percent)
      }
    };
    xhr.send(file);

  } 